$(window).on("load", function() {
	//
	// js pour l'ouverture du panel de gestion des cookies
	//
	$('a.open').on('click', function(event) {
		event.preventDefault();
		tarteaucitron.userInterface.openPanel();
	});


	//
	// js pour la réécriture des emails
	//
	function deObfuscateEmail() {
		var content = $(this).html().replace(new RegExp("{AT}", 'g'), '@').replace(new RegExp("{DOT}", 'g'), '.');
		$(this).html(content);
	}

	$('.obfuscated').each(deObfuscateEmail);


	// 
	// SCROLL POUR LES LIENS AVEC DES ANCRES
	//
    $('a[href*="#"]:not([href="#"])').on('click', function() {
        var target = $(this.hash);
        $('html,body').stop().animate({
            scrollTop: target.offset().top - 75
        }, 'linear');
    });    
    if(location.hash) {
        var id = $(location.hash);
        $('html,body').animate({scrollTop: id.offset().top -75}, 'linear');
    };


	//
	// js pour afficher/masquer le lien pour retourner en haut de page
	//
	if($('#scrollto').length) {
		var prevScrollpos = window.pageYOffset;

		window.onscroll = function() {
			var currentScrollPos = window.pageYOffset;

			if(prevScrollpos <= currentScrollPos || currentScrollPos <= 0) {
				$('#scrollto').css('right', -Math.ceil($('#scrollto').outerWidth()));
			} else {
				$('#scrollto').css('right', 0);
			}

			prevScrollpos = currentScrollPos;
		}
	}


	//
	// js pour activer/desactiver le mode passive afin d'améliorer les performances
	//
	$.event.special.touchmove = {
		setup: function(_, ns, handle) {
			if(ns.includes("noPreventDefault")) {
				this.addEventListener("touchmove", handle, { passive: false });
			} else {
				this.addEventListener("touchmove", handle, { passive: true });
			}
		}
	};

	$.event.special.touchstart = {
		setup: function(_, ns, handle) {
			if(ns.includes("noPreventDefault")) {
				this.addEventListener("touchstart", handle, { passive: false });
			} else {
				this.addEventListener("touchstart", handle, { passive: true });
			}
		}
	};
});
$(window).on("load", function() {
    $('#realisations-menu div').on('click', function () {
        let filter = $(this).data('filter');
        $('#realisations-menu div.btn-green').addClass('btn-green-outline').removeClass('btn-green');
        $(this).removeClass('btn-green-outline');
        $(this).addClass('btn-green');

        if(filter == '') {
            $('.realisation').fadeIn();
        } else {
            $('.realisation').each(function() {
                let terms = $(this).data('realisation-category').split(';');
                if(terms.includes(filter)) {
                    $(this).fadeIn();
                } else {
                    $(this).fadeOut('fast');
                }
            });
        }
    });

    
    // Get the modal
    var modal = document.getElementById("myModal");
    var modalImg = document.getElementById("img01");
    var realisations = document.getElementsByClassName('realisation-img');

    if(realisations.length > 0) {    
        for(let i=0; i < realisations.length; i++) {
            realisations[i].onclick = function(event) {
                event.preventDefault();
                event.stopPropagation();
        
                modal.style.display = "block";
                modalImg.src = this.href;
            }
        };
    
        // Get the <span> element that closes the modal
        var span = document.getElementsByClassName("close")[0];
    
        // When the user clicks on <span> (x), close the modal
        span.onclick = function() {
            modal.style.display = "none";
        }
    }

    
    var actualiteImgs = document.getElementsByClassName('actualite-img');
    if(actualiteImgs.length > 0) {
        for(let i=0; i < actualiteImgs.length; i++) {
            actualiteImgs[i].onclick = function(event) {
                event.preventDefault();
                event.stopPropagation();
        
                modal.style.display = "block";
                modalImg.src = this.href;
            }
        };
    
        // Get the <span> element that closes the modal
        var span = document.getElementsByClassName("close")[0];
    
        // When the user clicks on <span> (x), close the modal
        span.onclick = function() {
            modal.style.display = "none";
        }
    }

    $(document).on('keyup', function(e) {
        if(e.key === "Escape") {
            modal.style.display = "none";
        }
   });
    
	//
	// js pour afficher le formulaire de candidature
	//
	$('a#postuler').on('click', function(event) {
		event.preventDefault();

		$('div#candidature').removeClass('d-none');

		$('html, body').stop().animate({scrollTop: $('div#candidature').offset().top}, 500);
	});


    //
	// js pour mettre à jour un input dans un formulaire (formulaire de candidature)
	//
	$(document).on('change', 'input[name="c-prenom"]', function() {
		if($('input#c-annonce').val() == '') {
			$('input#c-annonce').val($('#reference').text().trim());
		}
	});

	if($('input#c-annonce').length) {
		$('input#c-annonce').val($('#reference').text().trim());
	}
});